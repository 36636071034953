@media (min-width: 452px) and (max-width: 1000000px) {
  .tileWidth-150 {
    width: 95vw !important;
  }
}

@media (min-width: 452px) and (max-width: 1000000px) {
  .tileWidth-120 {
    width: 100% !important;
  }
}

@media (min-width: 452px) and (max-width: 1000000px) {
  .tileWidth-90 {
    width: 100% !important;
  }
}

@media (min-width: 452px) and (max-width: 1000000px) {
  .tileWidth-60 {
    width: 100% !important;
  }
}
@media (min-width: 452px) and (max-width: 1000000px) {
  .tileWidth-38 {
    width: 100% !important;
  }
}
@media (min-width: 452px) and (max-width: 1000000px) {
  .tileWidth-26 {
    width: 100% !important;
  }
}
@media (min-width: 452px) and (max-width: 1000000px) {
  .tileWidth-19 {
    width: 100% !important;
  }
}
@media (min-width: 452px) and (max-width: 1000000px) {
  .tileWidth-14 {
    width: 100% !important;
  }
}
@media (min-width: 452px) and (max-width: 1000000px) {
  .tileWidth-10 {
    width: 100% !important;
  }
}
@media (min-width: 452px) and (max-width: 1000000px) {
  .tileWidth-6 {
    width: 100% !important;
  }
}
@media (min-width: 452px) and (max-width: 1000000px) {
  .tileWidth-3 {
    width: 100% !important;
  }
}
@media (min-width: 452px) and (max-width: 1000000px) {
  .tileWidth-2 {
    width: 100% !important;
  }
}
@media (min-width: 452px) and (max-width: 1000000px) {
  .tileWidth-1 {
    width: 100% !important;
  }
}

/*Mobil*/

@media (min-width: 0px) and (max-width: 451px) {
  .tileWidth-150 {
    width: 45vw !important;
  }
}
@media (min-width: 0px) and (max-width: 451px) {
  .tileWidth-120 {
    width: 95vw !important;
  }
}
@media (min-width: 0px) and (max-width: 451px) {
  .tileWidth-90 {
    width: 95vw !important;
  }
}
@media (min-width: 0px) and (max-width: 451px) {
  .tileWidth-60 {
    width: 95vw !important;
  }
}
@media (min-width: 0px) and (max-width: 451px) {
  .tileWidth-38 {
    width: 45vw !important;
  }
}

@media (min-width: 0px) and (max-width: 451px) {
  .tileWidth-26 {
    width: 45vw !important;
  }
}
@media (min-width: 0px) and (max-width: 451px) {
  .tileWidth-19 {
    width: 45vw !important;
  }
}
@media (min-width: 0px) and (max-width: 451px) {
  .tileWidth-14 {
    width: 29vw !important;
  }
}
@media (min-width: 0px) and (max-width: 451px) {
  .tileWidth-10 {
    width: 22vw !important;
  }
}
@media (min-width: 0px) and (max-width: 451px) {
  .tileWidth-6 {
    width: 15vw !important;
  }
}
@media (min-width: 0px) and (max-width: 451px) {
  .tileWidth-3 {
    width: 10vw !important;
  }
}
@media (min-width: 0px) and (max-width: 451px) {
  .tileWidth-2 {
    width: 10vw !important;
  }
}
@media (min-width: 0px) and (max-width: 451px) {
  .tileWidth-1 {
    width: 10vw !important;
  }
}
