@media (min-width: 451px) and (max-width: 1000000px) {
  .grid-150 {
    display: grid !important;

    grid-template-columns: 1fr !important;

    grid-gap: 1em;
  }
}
@media (min-width: 451px) and (max-width: 1000000px) {
  .grid-120 {
    display: grid !important;

    grid-template-columns: repeat(auto-fit, minmax(450px, 1fr)) !important;
  }
}
@media (min-width: 451px) and (max-width: 1000000px) {
  .grid-90 {
    display: grid !important;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr)) !important;
  }
}
@media (min-width: 451px) and (max-width: 1000000px) {
  .grid-60 {
    display: grid !important;

    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)) !important;

    /*
    grid-template-columns: 1fr 1fr 1fr 1fr !important;
*/
  }
}
@media (min-width: 451px) and (max-width: 1000000px) {
  .grid-38 {
    display: grid !important;

    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)) !important;
    /*  
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr !important;
*/
    grid-gap: 1em;
  }
}
@media (min-width: 451px) and (max-width: 1000000px) {
  .grid-26 {
    display: grid !important;
    /*
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr !important;*/
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)) !important;
  }
}
@media (min-width: 451px) and (max-width: 1000000px) {
  .grid-19 {
    display: grid !important;

    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr)) !important;
    /*
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr !important;
    */
    grid-column-gap: 1% !important;
  }
}
@media (min-width: 451px) and (max-width: 1000000px) {
  .grid-14 {
    display: grid !important;
    grid-template-columns: repeat(auto-fit, minmax(75px, 1fr)) !important;
    /*
    grid-template-columns: repeat(15, 6.3%) !important;*/
    /*grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr!important;
    */
    grid-column-gap: 0.5% !important;
    grid-row-gap: 3% !important;
  }
}
@media (min-width: 451px) and (max-width: 1000000px) {
  .grid-10 {
    display: grid !important;
    grid-template-columns: repeat(auto-fit, minmax(50px, 1fr)) !important;
    /*
    grid-template-columns: repeat(20, 5%) !important;
    */
    grid-column-gap: 0.5% !important;
    grid-row-gap: 2.5% !important;
  }
}
@media (min-width: 451px) and (max-width: 1000000px) {
  .grid-6 {
    display: grid !important;
    grid-template-columns: repeat(auto-fit, minmax(35px, 1fr)) !important;
    /*
    grid-template-columns: repeat(33, 3%) !important;
    */
    grid-column-gap: 0.3% !important;
    grid-row-gap: 1.5% !important;
  }
}
@media (min-width: 451px) and (max-width: 1000000px) {
  .grid-3 {
    display: grid !important;

    grid-template-columns: repeat(auto-fit, minmax(20px, 1fr)) !important;

    /*
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr!important;
*/

    grid-column-gap: 0.1% !important;

    grid-row-gap: 3% !important;
  }
}
@media (min-width: 451px) and (max-width: 1000000px) {
  .grid-2 {
    display: grid !important;

    grid-template-columns: repeat(auto-fit, minmax(15px, 1fr)) !important;
    /*
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr!important;
*/

    grid-row-gap: 6.2% !important;

    grid-column-gap: 0.05% !important ;
  }
}
@media (min-width: 451px) and (max-width: 1000000px) {
  .grid-1 {
    display: grid !important;

    grid-template-columns: repeat(auto-fit, minmax(10px, 1fr)) !important;
    /*
    grid-template-columns:1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr!important;
*/

    grid-row-gap: 6.2% !important;

    grid-column-gap: 0.05% !important;
  }
}

/*mobil*/

@media (min-width: 0px) and (max-width: 450px) {
  .grid-150 {
    display: grid !important;

    grid-template-columns: 1fr !important;

    grid-gap: 1em;
  }
}
@media (min-width: 0px) and (max-width: 450px) {
  .grid-120 {
    display: grid !important;

    grid-template-columns: 1fr !important;
  }
}
@media (min-width: 0px) and (max-width: 450px) {
  .grid-90 {
    display: grid !important;

    grid-template-columns: 1fr !important;

    grid-gap: 1em;
  }
}
@media (min-width: 0px) and (max-width: 450px) {
  .grid-60 {
    display: grid !important;

    grid-template-columns: 1fr !important;

    grid-gap: 1em;
  }
}
@media (min-width: 0px) and (max-width: 450px) {
  .grid-38 {
    display: grid !important;
    grid-template-columns: 1fr 1fr !important;
    grid-gap: 1em;
  }
}
@media (min-width: 0px) and (max-width: 450px) {
  .grid-26 {
    display: grid !important;
    grid-template-columns: 1fr 1fr !important;
    grid-gap: 1em;
  }
}
@media (min-width: 0px) and (max-width: 450px) {
  .grid-19 {
    display: grid !important;
    grid-template-columns: 1fr 1fr !important;
    grid-column-gap: 0.4%;
  }
}
@media (min-width: 0px) and (max-width: 450px) {
  .grid-14 {
    display: grid !important;
    grid-template-columns: 1fr 1fr 1fr !important;
    grid-gap: 1em;
  }
}
@media (min-width: 0px) and (max-width: 450px) {
  .grid-10 {
    display: grid !important;
    grid-template-columns: 1fr 1fr 1fr 1fr !important;
    grid-column-gap: 0.5% !important ;
  }
}
@media (min-width: 0px) and (max-width: 450px) {
  .grid-6 {
    display: grid !important;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr !important;
    grid-column-gap: 0.5% !important ;
  }
}

@media (min-width: 0px) and (max-width: 450px) {
  .grid-3 {
    display: grid !important;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr !important;
    grid-gap: 1em;
  }
}
@media (min-width: 0px) and (max-width: 450px) {
  .grid-2 {
    display: grid !important;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr !important;
  }
}
@media (min-width: 0px) and (max-width: 450px) {
  .grid-1 {
    display: grid !important;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr !important;
  }
}
/*
@media (min-width: 0px) and (max-width: 400px) {
  .grid-26{

    display: grid !important;
    
    grid-template-columns: 1fr 1fr !important;
    
    grid-gap: 0.01% !important;
  }
}

@media (min-width: 0px) and (max-width: 400px) {
  .grid-10{

    display: grid !important;
    
    grid-template-columns: 1fr 1fr !important;

    grid-gap: 0.01% !important;
  }
}*/

/*
@media (min-width: 0px) and (max-width: 767px) {
  .grid-4{

    display: grid !important;
    
    grid-template-columns: 1fr !important;

  }
}

@media (min-width: 768px) and (max-width: 1000px) {
  .grid-4{

    display: grid !important;
    
    grid-template-columns: 1fr 1fr !important;

  }
}


@media (min-width: 1001px) and (max-width: 1600px) {
  .grid-4{

    display: grid !important;
    
    grid-template-columns: 1fr 1fr 1fr 1fr !important;

  }
}
@media (min-width: 1601px) and (max-width: 2000px) {
  .grid-4{

    display: grid !important;
    
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr !important;

  }
}


*/

/*

@media (min-width: 0px) and (max-width: 299px) {
    .archive {

        display: grid !important;
        
        grid-template-columns: 1fr !important;
    
        grid-gap: 1em !important; 
      }

      .nameSizing{
        font-size: 18px !important;
        width: 100%;
        margin: 120px 0;
      }

      .statusSizing{
        font-size: 16px !important;
      }
      
      .durationSizing{
        font-size: 14px !important;
      }
      .rpmSizing{
        font-size: 14px !important;
      }
      .statusMovementSizing{
        font-size: 14px !important;
      }
}

@media (min-width: 300px) and (max-width: 399px)  {
    .archive {

        display: grid !important;
        
        grid-template-columns: 1fr !important;
    
        grid-gap: 1em !important; 
      }

      .nameSizing{
        font-size: 26px !important;
        width: 100%;
        margin: 120px 0;
      }
      .statusSizing{
        font-size: 22px !important;
      }
      .durationSizing{
        font-size: 16px !important;
      }
      .rpmSizing{
        font-size: 18px !important;
        margin-left: 85px !important;
      }
      .statusMovementSizing{
        font-size: 18px !important;
      }
      .avatarSizing{
        height: 70px !important;
        width: 70px !important;
      }
}
@media (min-width: 400px) and (max-width: 480px)  {
  .archive {

      display: grid ;
      
      grid-template-columns: 1fr ;
  
      grid-gap: 1em ; 
    }
    .customCard{
      height: 260px
    }
    .nameSizing{
      font-size: 30px !important;
      width: 100%;
      margin: 120px 0;
    }
    .statusSizing{
      font-size: 26px !important;
    }
    .durationSizing{
      font-size: 22px !important;
    }
    .avatarSizing{
      height: 100px !important;
      width: 100px !important;
    }
    .rpmSizing{
      font-size: 24px !important;
      margin-left: 115px !important;
    }
    .statusMovementSizing{
      font-size: 24px !important;
    }

}

@media (min-width: 481px) and (max-width: 580px)  {
  .archive {

      display: grid ;
      
      grid-template-columns: 1fr 1fr ;
  
      grid-gap: 1em ; 
    }

    .nameSizing{
      font-size: 18px !important;
      width: 100%;
      margin: 120px 0;
    }
    .statusSizing{
      font-size: 16px !important;
    }
    .durationSizing{
      font-size: 14px !important;
    }
    .avatarSizing{
      height: 50px !important;
      width: 50px !important;
    }
    .rpmSizing{
      font-size: 16px !important;
      margin-left: 65px !important;
    }
    .statusMovementSizing{
      font-size: 16px !important;
    }
}
@media (min-width: 581px) and (max-width: 699px) {
  .archive {

      display: grid ;
      
      grid-template-columns: 1fr 1fr ;
  
      grid-gap: 1em ; 
    }
    .nameSizing{
      font-size: 22px !important;
      width: 100%;
      margin: 120px 0;
    }
    .statusSizing{
      font-size: 16px !important;
    }
    .durationSizing{
      font-size: 14px !important;
    }
    .avatarSizing{
      height: 50px !important;
      width: 50px !important;
    }
    .rpmSizing{
      font-size: 16px !important;
      margin-left: 65px !important;
    }
    .statusMovementSizing{
      font-size: 16px !important;
    }
}
@media (min-width: 700px) and (max-width: 900px) {
  .archive {

      display: grid ;
      
      grid-template-columns: 1fr 1fr ;
  
      grid-gap: 1em ; 
    }
  .customCard{
      height: 255px;
  }
  .nameSizing{
    font-size: 26px !important;
    width: 100%;
    margin: 120px 0;
  }
  .avatarSizing{
    height: 100px !important;
    width: 100px !important;
  }
  .statusSizing{
    font-size: 22px !important;
  }
  .durationSizing{
    font-size: 18px !important;
  }
  .statusMovementSizing{
    font-size: 22px !important;
  }
  .rpmSizing{
    font-size: 22px !important;
    margin-left: 115px !important;
  }

}
/*
@media (min-width: 901px) and (max-width: 1200px) {
  .archive {

      display: grid ;
      
      grid-template-columns: 1fr 1fr 1fr ;
  
      grid-gap: 1em ; 
    }
    .customCard{
      height: 255px;
    }
    .nameSizing{
      font-size: 24px !important;
      width: 100%;
      margin: 120px 0;
    }
    .avatarSizing{
      height: 100px !important;
      width: 100px !important;
    }
    .statusSizing{
      font-size: 22px !important;
    }
    .durationSizing{
      font-size: 18px !important;
    }
    .rpmSizing{
      font-size: 22px !important;
      margin-left: 115px !important;
    }
    .statusMovementSizing{
      font-size: 22px !important;
      margin-bottom: 30px !important;
    }
}*/

/*
@media (min-width: 1201px) and (max-width: 1500px) {
  .archive {

      display: grid ;
      
      grid-template-columns: 1fr 1fr 1fr ;
  
      grid-gap: 1em ; 
    }
    .customCard{
      height: 265px;
    }
    .nameSizing{
      font-size: 27px !important;
      width: 100%;
      margin: 120px 0;
    }
    .avatarSizing{
      height: 115px !important;
      width: 115px !important;
    }
    .statusSizing{
      font-size: 23px !important;
    }
    .durationSizing{
      font-size: 19px !important;
    }
    .rpmSizing{
      font-size: 23px !important;
      margin-left: 130px !important;
    }
    .statusMovementSizing{
      font-size: 23px !important;
      margin-bottom: 30px !important;
    }
}

@media (min-width: 1501px) and (max-width: 1550px) {
  .archive {

      display: grid ;
      
      grid-template-columns: 1fr 1fr 1fr ;
  
      grid-gap: 1em ; 
    }
    .customCard{
      height: 275px;
    }
    .nameSizing{
      font-size: 28px !important;
      width: 100%;
      margin: 120px 0;
    }
    .avatarSizing{
      height: 125px !important;
      width: 125px !important;
    }
    .statusSizing{
      font-size: 24px !important;
    }
    .durationSizing{
      font-size: 20px !important;
    }
    .rpmSizing{
      font-size: 24px !important;
      margin-left: 140px !important;
    }

}



@media (min-width: 1551px) and (max-width: 1800px) {
  .archive {

      display: grid;
      
      grid-template-columns: 1fr 1fr 1fr 1fr ;
  
      grid-gap: 1em ; 
    }
    .customCard{
      height: 235px;
    }
    .nameSizing{
      font-size: 30px !important;
      width: 100%;
      margin: 120px 0;
    }
    .avatarSizing{
      height: 85px !important;
      width: 85px !important;
    }
    .statusSizing{
      font-size: 24px !important;
    }
    .durationSizing{
      font-size: 20px !important;
    }
    .rpmSizing{
      font-size: 24px !important;
      margin-left: 100px !important;
    }
    .statusMovementSizing{
      font-size: 24px !important;
      
    }

}

@media (min-width: 1801px) and (max-width: 2000px) {
  .archive {

      display: grid ;
      
      grid-template-columns: 1fr 1fr 1fr 1fr ;
  
      grid-gap: 1em ; 
    }
    .customCard{
      height: 260px;
    }
    .nameSizing{
      font-size: 32px !important;
      width: 100%;
      margin: 120px 0;
    }
    .avatarSizing{
      height: 105px !important;
      width: 105px !important;
    }
    .statusSizing{
      font-size: 28px !important;
    }
    .durationSizing{
      font-size: 24px !important;
    }
    .rpmSizing{
      font-size: 24px !important;
      margin-left: 120px !important;
    }
    .statusMovementSizing{
      font-size: 24px !important;
    }

}


@media (min-width: 2000px) and (max-width: 2200px) {
  .archive {

    display: grid ;
    
    grid-template-columns: 1fr 1fr 1fr 1fr ;

    grid-gap: 1em ; 
  }
  .customCard{
    height: 295px;
  }
  .nameSizing{
    font-size: 36px !important;
    width: 100%;
    margin: 120px 0;
  }
  .avatarSizing{
    height: 125px !important;
    width: 125px !important;
  }
  .statusSizing{
    font-size: 32px !important;
  }
  .durationSizing{
    font-size: 28px !important;
  }
  .rpmSizing{
    font-size: 28px !important;
    margin-left: 140px !important;
  }
  .statusMovementSizing{
    font-size: 28px !important;
  }
}
@media (min-width: 2200px) and (max-width: 2500px) {
  .archive {

    display: grid ;
    
    grid-template-columns: 1fr 1fr 1fr 1fr ;

    grid-gap: 1em ; 
  }
  .customCard{
    height: 335px;
  }
  .nameSizing{
    font-size: 36px !important;
    width: 100%;
    margin: 120px 0;
  }
  .avatarSizing{
    height: 145px !important;
    width: 145px !important;
  }
  .statusSizing{
    font-size: 32px !important;
  }
  .durationSizing{
    font-size: 28px !important;
  }
  .rpmSizing{
    font-size: 28px !important;
    margin-left: 160px !important;
  }
  .statusMovementSizing{
    font-size: 28px !important;
  }
}
@media (min-width: 2500px) and (max-width: 2800px) {
  .archive {

    display: grid ;
    
    grid-template-columns: 1fr 1fr 1fr 1fr;

    grid-gap: 1em ; 
  }
  .customCard{
    height: 355px;
  }
  .nameSizing{
    font-size: 38px !important;
    width: 100%;
    margin: 120px 0;
  }
  .avatarSizing{
    height: 160px !important;
    width: 160px !important;
  }
  .statusSizing{
    font-size: 34px !important;
  }
  .durationSizing{
    font-size: 30px !important;
  }
  .rpmSizing{
    font-size: 34px !important;
    margin-left: 190px !important;
  }
  .statusMovementSizing{
    font-size: 34px !important;
    margin-bottom: 30px !important;
  }
}

@media (min-width: 2801px) and (max-width: 3001px) {
  .archive {

    display: grid ;
    
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 1em ;
  }
  .customCard{
    height: 400px;
  }
  .nameSizing{
    font-size: 42px !important;
    width: 100%;
    margin: 120px 0;
  }
  .avatarSizing{
    height: 175px !important;
    width: 175px !important;
  }
  .statusSizing{
    font-size: 38px !important;
  }
  .durationSizing{
    font-size: 34px !important;
  }
  .rpmSizing{
    font-size: 34px !important;
    margin-left: 190px !important;
  }
  .statusMovementSizing{
    font-size: 34px !important;
  }
  
}




    .customCard{
      height: 550px
    }
    .nameSizing{
      font-size: 65px !important;
      width: 100%;
      margin: 120px 0;
    }
    .avatarSizing{
      height: 275px !important;
      width: 275px !important;
    }
    .statusSizing{
      font-size: 61px !important;
    }
    .durationSizing{
      font-size: 57px !important;
    }
    .rpmSizing{
      font-size: 57px !important;
      margin-left: 290px !important;
    }
    .statusMovementSizing{
      font-size: 57px !important;
      margin-bottom: 70px !important;
    }
@media (min-width: 5001px) and (max-width: 7000px) {
  .archive {

      display: grid ;
      
      grid-template-columns: 1fr 1fr 1fr 1fr !important;
  
      grid-gap: 1em ; 
    }
    .customCard{
      height: 850px;
    }
    .nameSizing{
      font-size: 90px !important;
      width: 100%;
      margin: 120px 0;
    }
    .avatarSizing{
      height: 405px !important;
      width: 405px !important;
    }
    .statusSizing{
      font-size: 86px !important;
    }
    .durationSizing{
      font-size: 82px !important;
    }
    .rpmSizing{
      font-size: 82px !important;
      margin-left: 410px !important;
      margin-top: 80px !important;
    }
    .statusMovementSizing{
      font-size: 82px !important;
      margin-top: 80px !important;
    }
}

@media (min-width: 7001px) and (max-width: 15000px) {
  .archive {

      display: grid ;
      
      grid-template-columns: 1fr 1fr 1fr 1fr !important;
  
      grid-gap: 1em ; 
    }
    .customCard{
      height: 1150px;
    }
    .nameSizing{
      font-size: 120px !important;
      width: 100%;
      margin: 120px 0;
    }
    .avatarSizing{
      height: 545px !important;
      width: 545px !important;
    }
    .statusSizing{
      font-size: 116px !important;
    }
    .durationSizing{
      font-size: 112px !important;
    }
    .rpmSizing{
      font-size: 112px !important;
      margin-left: 550px !important;
      margin-top: 150px !important;
    }
    .statusMovementSizing{
      font-size: 112px !important;
      margin-top: 150px !important;
    }
}

*/
